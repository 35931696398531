import React from 'react'
import { LocaleContext } from 'gatsby-theme-i18n'
import * as styles from './styles.module.scss'

const Block = () => {
  const locale = React.useContext(LocaleContext)

  const localeStrings = {
    en: {
      message: '404: not found',
    },
    zh: {
      message: '404: 没有找到页面',
    },
  }
  const strings = localeStrings[locale]

  return (
    <div className={styles.component}>
      <h3>{strings.message}</h3>
    </div>
  )
}

export default Block
